<template>
  <div class="address-copy">
    <ui-text :variant :color>
      {{ formatAddress }}
    </ui-text>
    <div ref="addressRef" class="address__value">{{ address }}</div>
    <ui-icon
      :svg="SVGCopy"
      type="button"
      class="svg__copy"
      @click="onClickCopy"
    ></ui-icon>
  </div>
</template>
<script setup lang="ts">
import SVGCopy from '@base/assets/img/svg/icon-copy.svg'
import { addressCopyEmits, addressCopyProps } from './address-copy'
/**
 * support copy address
 */
const props = defineProps(addressCopyProps)

const addressRef: Ref<HTMLDivElement | null> = ref(null)
const formatAddress = computed(() => {
  let formatString = props.address
  if (props.ellipsis && props.address) {
    formatString = abbreviatedAddress(props.address, props.addressFormatOptions)
  }

  return formatString
})

// [ Copy ]
const { copy } = useCopyText()
const onClickCopy = (e: MouseEvent) => {
  e.stopPropagation()
  if (addressRef.value) {
    const copiedValue = copy(addressRef.value)
    if (copiedValue) {
      emits('copy', copiedValue)
    }
  }
}
const emits = defineEmits(addressCopyEmits)
</script>
<style lang="postcss" scoped>
.address-copy {
  display: inline-flex;
  align-items: center;

  & .address__value {
    position: absolute;
    top: -99999999px;
    left: -99999999px;
    z-index: -1;
  }
  & .svg__copy {
    margin-left: 4px;
  }
}
</style>
