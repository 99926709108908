/**
 * Copy element text, cannot be used on display:none; or visibility: hidden; elements, you can use `position: absolute;top: 0;z-index: -1;` to make the element invisible and can be copied
 */
export function useCopyText() {
  const copy = (el: Node) => {
    if (!useIsClient()) return
    let copiedValue: string | undefined
    const selection = window.getSelection()
    if (selection) {
      if (el.textContent) {
        copiedValue = el.textContent
      }
      // Clear the selection object
      selection.removeAllRanges()
      // Create a Range instance

      const range = new Range()
      range.selectNodeContents(el)

      // Set the range instance for the selection object
      selection.addRange(range)

      document.execCommand('Copy')
      selection.removeAllRanges()
    }
    return copiedValue
  }
  return {
    copy,
  }
}
