import type { ExtractPropTypes, ExtractPublicPropTypes } from 'vue'
import type { TextColor, TextVariant } from '@base/components/ui/text'
import type { AbbreviatedAddressParams } from '@base/utils'

export const addressCopyProps = {
  address: {
    type: String,
    required: true,
  },
  /**
   * use ellipsis
   */
  ellipsis: {
    type: Boolean,
    default: false,
  },
  /**
   * same prop with Text component
   */
  variant: {
    type: String as PropType<TextVariant>,
    default: 'body3',
  },
  /**
   * same prop with Text component
   */
  color: {
    type: String as PropType<TextColor>,
    default: 'textSecondary',
  },
  /**
   * Options for formatting abbreviated address
   * @default { start: 7, end: 5, dots: 3 }
   */
  addressFormatOptions: {
    type: Object as PropType<AbbreviatedAddressParams>,
    default: () => ({ start: 7, end: 5, dots: 3 }),
  },
}
export type AddressCopyProps = ExtractPropTypes<typeof addressCopyProps>
export type AddressCopyPublicProps = ExtractPublicPropTypes<
  typeof addressCopyProps
>
export const addressCopyEmits = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  copy: (value: string) => undefined,
} as const
export type AddressCopyEmits = typeof addressCopyEmits
